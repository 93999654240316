import React, { useState, useCallback, useEffect } from 'react';
import { BsLightning } from 'react-icons/bs'; // Add this import
import useGeocoding from '../../hooks/useGeocoding';
import { debounce } from 'lodash';
import './SearchForm.css'; // Add this import

const radiusOptions = [
  { label: '1 mile', value: 1609.34 },
  { label: '2 miles', value: 3218.69 },
  { label: '3 miles', value: 4828.03 },
  { label: '4 miles', value: 6437.38 },
  { label: '5 miles', value: 8046.72 },
  { label: '10 miles', value: 16093.4 },
  { label: '20 miles', value: 32186.9 }
];

const yearsOptions = [
  { label: '1 year', value: 1 },
  { label: '2 years', value: 2 },
  { label: '3 years', value: 3 },
  { label: '4 years', value: 4 },
  { label: '5 years', value: 5 },
  { label: '10 years', value: 10 },
  { label: '15 years', value: 15 },
  { label: '20 years', value: 20 }
];

function SearchForm({
  address,
  setAddress,
  radius,
  setRadius,
  yearsBack,
  setYearsBack,
  dataSource,
  setDataSource,
  onSearch,
  loading
}) {
  const [autocompleteEnabled, setAutocompleteEnabled] = useState(false);
  const { getAddressSuggestions } = useGeocoding();
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);

   // Set default values
   useEffect(() => {
    if (!radius) setRadius(3218.69); // Default to 2 miles
    if (!yearsBack) setYearsBack(5); // Default to 5 years
    if (!dataSource) setDataSource('Filtered'); // Changed from 'NOAA' to 'Filtered'
  }, [radius, yearsBack, dataSource, setRadius, setYearsBack, setDataSource]);

  const debouncedGetSuggestions = useCallback(
    debounce(async (input) => {
      if (autocompleteEnabled && input.length > 2) {
        const suggestions = await getAddressSuggestions(input);
        setAddressSuggestions(suggestions);
      } else {
        setAddressSuggestions([]);
      }
    }, 300),
    [autocompleteEnabled, getAddressSuggestions]
  );

  const handleAddressChange = (e) => {
    const input = e.target.value;
    setAddress(input);
    debouncedGetSuggestions(input);
  };

  const handleSuggestionClick = (suggestion) => {
    setAddress(suggestion);
    setAddressSuggestions([]);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setActiveIndex(prev => (prev < addressSuggestions.length - 1 ? prev + 1 : prev));
    } else if (e.key === 'ArrowUp') {
      setActiveIndex(prev => (prev > 0 ? prev - 1 : prev));
    } else if (e.key === 'Enter' && activeIndex !== -1) {
      handleSuggestionClick(addressSuggestions[activeIndex]);
    }
  };

  return (
    <div className="search-form">
      <div className="address-section">
        <div className="form-group address-group">
          <label htmlFor="address">Address:</label>
          <div className="input-row">
            <div className="autocomplete-wrapper">
              <input
                id="address"
                type="text"
                value={address}
                onChange={handleAddressChange}
                onKeyDown={handleKeyDown}
                placeholder="Enter address"
                className="form-control address-input"
                aria-autocomplete="list"
                aria-controls="address-suggestions"
              />
              <div 
                className={`toggle-switch ${autocompleteEnabled ? 'active' : ''}`}
                onClick={() => setAutocompleteEnabled(!autocompleteEnabled)}
                title="Enable auto-complete"
              >
                <BsLightning className="lightning-icon" />
              </div>
              {autocompleteEnabled && addressSuggestions.length > 0 && (
                <ul id="address-suggestions" className="suggestions-list">
                  {addressSuggestions.map((suggestion, index) => (
                    <li 
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion)}
                      className={index === activeIndex ? 'active' : ''}
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="search-options">
        <div className="form-group">
          <label htmlFor="radius">Search Radius:</label>
          <select
            id="radius"
            value={radius}
            onChange={(e) => setRadius(Number(e.target.value))}
            className="form-control"
          >
            {radiusOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="yearsBack">Years ago:</label>
          <select
            id="yearsBack"
            value={yearsBack}
            onChange={(e) => setYearsBack(Number(e.target.value))}
            className="form-control"
          >
            {yearsOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="dataSource">Data Source:</label>
          <select
            id="dataSource"
            value={dataSource}
            onChange={(e) => setDataSource(e.target.value)}
            className="form-control"
          >
            <option value="Filtered">Filtered Sightings</option>
            <option value="Unfiltered">Unfiltered Sightings</option>
            <option value="Radar">Radar Data</option>
            <option value="NOAA">NOAA Hail Events Archive</option>
          </select>
        </div>
      </div>

      <button 
        onClick={onSearch} 
        disabled={loading || !address}
        className="search-button"
      >
        {loading ? 'Searching...' : 'Search'}
      </button>
    </div>
  );
}

export default SearchForm;