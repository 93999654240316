import { useState, useEffect, useCallback } from 'react';
import useGeocoding from './useGeocoding';

const TIMEOUT_MS = 30000; // 30 seconds timeout

function useStormEvents(supabase) {
  const [address, setAddress] = useState('');
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [coordinates, setCoordinates] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [radius, setRadius] = useState(1609.34);
  const [yearsBack, setYearsBack] = useState(1);
  const [error, setError] = useState(null);
  const [dataSource, setDataSource] = useState('Filtered');

  const { getCoordinates } = useGeocoding();

  useEffect(() => {
    // Clear events when dataSource changes
    setEvents([]);
    setTotalCount(0);
  }, [dataSource]);

  const timeoutPromise = (ms) => {
    return new Promise((_, reject) => {
      setTimeout(() => reject(new Error('Request timeout')), ms);
    });
  };

  const fetchWithTimeout = async (promise) => {
    return Promise.race([
      promise,
      timeoutPromise(TIMEOUT_MS)
    ]);
  };

  const fetchNOAAData = useCallback(async (params) => {
    const { data, error, count } = await supabase.rpc('get_storm_events_nearby', params)
      .range(0, 999)
      .select('*', { count: 'exact' });

    if (error) throw error;
    setEvents(data);
    setTotalCount(count);
  }, [supabase]);

  const fetchSightingsData = useCallback(async (tableName, lat, lng, startDate, endDate, radius, source) => {
    const radiusDegrees = radius / 111000;

    let query = supabase
      .from(tableName)
      .select('*', { count: 'exact' })
      .filter('Lat', 'gte', lat - radiusDegrees)
      .filter('Lat', 'lte', lat + radiusDegrees)
      .filter('Lon', 'gte', lng - radiusDegrees)
      .filter('Lon', 'lte', lng + radiusDegrees)
      .gte('date', startDate.toISOString().split('T')[0])
      .lte('date', endDate.toISOString().split('T')[0]);
    
    // Apply additional filtering for filtered dataset
    if (tableName === 'dailyhailsitings_filtered') {
      // Use a more lenient approach - just check if the record has a Size
      query = query.not('Size', 'is', null);
      // Only apply size filtering if we need to distinguish between tables
      // No size threshold for now since we're getting no results
    }
    
    const { data, error, count } = await query;
    
    if (error) throw error;
    
    const processedData = data.map(item => {
      // Validate required fields
      if (!item.Lat || !item.Lon || !item.date) {
        console.warn(`Invalid record found in ${tableName}:`, item);
        return null;
      }

      // Validate size format
      const size = parseFloat(item.Size);
      const sizeInInches = !isNaN(size) ? (size / 100).toFixed(2) : 'N/A';

      return {
        ...item,
        SizeInInches: sizeInInches,
        BEGIN_DATE_TIME: combineDateTime(item.date, item.Time),
        source
      };
    }).filter(Boolean); // Remove null entries

    const filteredData = processedData.filter(item => 
      calculateDistance(lat, lng, item.Lat, item.Lon) <= radius / 1609.34
    );

    setEvents(filteredData);
    setTotalCount(filteredData.length);
  }, [supabase]);

  const fetchHailSightings = (...args) => fetchSightingsData('dailyhailsitings', ...args, 'Sightings');
  const fetchFilteredSightings = (...args) => fetchSightingsData('dailyhailsitings_filtered', ...args, 'Filtered');

  const fetchRadarData = useCallback(async (lat, lng, startDate, endDate, radius) => {
    const radiusDegrees = radius / 111000;

    const { data, error, count } = await supabase
      .from('noaa_hail_radar_sm')
      .select('*', { count: 'exact' })
      .filter('LAT', 'gte', lat - radiusDegrees)
      .filter('LAT', 'lte', lat + radiusDegrees)
      .filter('LON', 'gte', lng - radiusDegrees)
      .filter('LON', 'lte', lng + radiusDegrees)
      .gte('ZTIME', startDate.toISOString())
      .lte('ZTIME', endDate.toISOString())
      .limit(1000);

    if (error) throw error;

    const normalizedData = data
      .map(item => {
        // Validate coordinates
        if (!item.LAT || !item.LON || isNaN(parseFloat(item.LAT)) || isNaN(parseFloat(item.LON))) {
          console.warn('Invalid coordinates in radar data:', item);
          return null;
        }

        return {
          ...item,
          BEGIN_LAT: item.LAT,
          BEGIN_LON: item.LON,
          BEGIN_DATE_TIME: item.ZTIME,
          MAGNITUDE: item.MAXSIZE,
          MAGNITUDE_TYPE: 'inches',
          EVENT_TYPE: 'Radar Detected Hail',
          EVENT_NARRATIVE: `WSR ID: ${item.WSR_ID}, Cell ID: ${item.CELL_ID}, Probability: ${item.PROB}%, Severity: ${item.SEVPROB}%`,
          SOURCE: 'Radar'
        };
      })
      .filter(Boolean); // Remove null entries

    setEvents(normalizedData);
    setTotalCount(count);
  }, [supabase]);

  const combineDateTime = (date, time) => {
    if (!date) return null;
    
    let timeString = '00:00:00'; // Default time if not provided
    
    if (time) {
      // Parse the time format "1907" into "19:07:00"
      if (time.length === 4) {
        const hours = time.slice(0, 2);
        const minutes = time.slice(2, 4);
        timeString = `${hours}:${minutes}:00`;
      } else {
        console.warn(`Unexpected time format: ${time}`);
      }
    }
    
    // Combine date and time
    const combinedDateTime = `${date}T${timeString}`;
    
    // Validate the combined date-time
    const dateObj = new Date(combinedDateTime);
    if (isNaN(dateObj.getTime())) {
      console.error('Invalid date-time:', combinedDateTime);
      return null;
    }
    
    return combinedDateTime;
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a 
      = Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    const d = R * c; // Distance in km
    return d * 0.621371; // Convert to miles
  };

  const deg2rad = (deg) => {
    return deg * (Math.PI/180)
  };

  const calculateDirection = (lat1, lon1, lat2, lon2) => {
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const lat1Rad = lat1 * (Math.PI / 180);
    const lat2Rad = lat2 * (Math.PI / 180);
    
    const y = Math.sin(dLon) * Math.cos(lat2Rad);
    const x = Math.cos(lat1Rad) * Math.sin(lat2Rad) -
             Math.sin(lat1Rad) * Math.cos(lat2Rad) * Math.cos(dLon);
    
    let bearing = Math.atan2(y, x) * (180 / Math.PI);
    bearing = (bearing + 360) % 360;
    
    // Convert bearing to cardinal direction
    const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
    const index = Math.round(bearing / 45) % 8;
    return directions[index];
  };

  const handleSearch = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const { lat, lng } = await getCoordinates(address);
      setCoordinates({ lat, lng });

      const endDate = new Date();
      const startDate = new Date();
      startDate.setFullYear(endDate.getFullYear() - yearsBack);

      if (dataSource === 'NOAA') {
        await fetchNOAAData({
          lat,
          lon: lng,
          radius: parseFloat(radius),
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString()
        });
      } else if (dataSource === 'Radar') {
        await fetchRadarData(lat, lng, startDate, endDate, parseFloat(radius));
      } else if (dataSource === 'Filtered') {
        await fetchFilteredSightings(lat, lng, startDate, endDate, parseFloat(radius));
      } else {
        await fetchHailSightings(lat, lng, startDate, endDate, parseFloat(radius));
      }
    } catch (error) {
      setError('An error occurred while fetching data. Please try again later.');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  }, [address, yearsBack, radius, dataSource, getCoordinates, fetchNOAAData, fetchRadarData, fetchHailSightings, fetchFilteredSightings]);

  // Trigger search when dataSource changes
  useEffect(() => {
    if (coordinates) {
      handleSearch();
    }
  }, [dataSource]);

  const fetchHailDataForLocation = useCallback(async (lat, lng, startDate, endDate, radius, lastUpdate = null) => {
    try {
      // 1. Fetch NOAA data
      let noaaQuery = supabase.rpc('get_storm_events_nearby', {
        lat,
        lon: lng,
        radius: parseFloat(radius),
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString()
      }).select('*');

      // Add updated_on filter if lastUpdate is provided
      if (lastUpdate) {
        noaaQuery = noaaQuery.gt('updated_on', lastUpdate);
      }

      const { data: noaaData } = await noaaQuery;

      // 2. Fetch Radar data
      const radiusDegrees = radius / 111000;
      let radarQuery = supabase
        .from('noaa_hail_radar_sm')
        .select('*')
        .filter('LAT', 'gte', lat - radiusDegrees)
        .filter('LAT', 'lte', lat + radiusDegrees)
        .filter('LON', 'gte', lng - radiusDegrees)
        .filter('LON', 'lte', lng + radiusDegrees)
        .gte('ZTIME', startDate.toISOString())
        .lte('ZTIME', endDate.toISOString());

      if (lastUpdate) {
        radarQuery = radarQuery.gt('updated_on', lastUpdate);
      }

      const { data: radarData } = await radarQuery;

      // 3. Fetch Filtered sightings from filtered table
      let filteredQuery = supabase
        .from('dailyhailsitings_filtered')
        .select('*')
        .filter('Lat', 'gte', lat - radiusDegrees)
        .filter('Lat', 'lte', lat + radiusDegrees)
        .filter('Lon', 'gte', lng - radiusDegrees)
        .filter('Lon', 'lte', lng + radiusDegrees)
        .gte('date', startDate.toISOString().split('T')[0])
        .lte('date', endDate.toISOString().split('T')[0]);

      // Apply additional filtering criteria for filtered data
      filteredQuery = filteredQuery.not('Size', 'is', null);
                                 
      if (lastUpdate) {
        filteredQuery = filteredQuery.gt('updated_on', lastUpdate);
      }

      // 4. Fetch Unfiltered sightings from original table
      let sightingsQuery = supabase
        .from('dailyhailsitings')
        .select('*')
        .filter('Lat', 'gte', lat - radiusDegrees)
        .filter('Lat', 'lte', lat + radiusDegrees)
        .filter('Lon', 'gte', lng - radiusDegrees)
        .filter('Lon', 'lte', lng + radiusDegrees)
        .gte('date', startDate.toISOString().split('T')[0])
        .lte('date', endDate.toISOString().split('T')[0]);

      if (lastUpdate) {
        sightingsQuery = sightingsQuery.gt('updated_on', lastUpdate);
      }

      const [
        noaaResult,
        radarResult,
        filteredResult,
        sightingsResult
      ] = await Promise.all([
        noaaQuery,
        radarQuery,
        filteredQuery,
        sightingsQuery
      ]);

      // Process the data
      const processedFilteredData = (filteredResult.data || []).map(item => ({
        ...item,
        BEGIN_DATE_TIME: combineDateTime(item.date, item.Time),
        MAGNITUDE: (parseFloat(item.Size) / 100).toFixed(2),
        source: 'Filtered',
        BEGIN_LAT: item.Lat,  // Change Lat to BEGIN_LAT for consistency
        BEGIN_LON: item.Lon,  // Change Lon to BEGIN_LON for consistency
        SizeInInches: item.Size ? (parseFloat(item.Size) / 100).toFixed(2) : 'N/A'
      }));

      const processedRadarData = (radarResult.data || []).map(item => ({
        ...item,
        BEGIN_DATE_TIME: item.ZTIME,
        MAGNITUDE: item.MAXSIZE,
        source: 'Radar'
      }));

      const processedSightingsData = (sightingsResult.data || []).map(item => ({
        ...item,
        BEGIN_DATE_TIME: combineDateTime(item.date, item.Time),
        MAGNITUDE: (parseFloat(item.Size) / 100).toFixed(2),
        source: 'Unfiltered', // Changed from 'Sightings' to 'Unfiltered'
        BEGIN_LAT: item.Lat,  // Change Lat to BEGIN_LAT for consistency
        BEGIN_LON: item.Lon,  // Change Lon to BEGIN_LON for consistency
        SizeInInches: item.Size ? (parseFloat(item.Size) / 100).toFixed(2) : 'N/A'
      }));

      return {
        noaa: noaaResult.data || [],
        radar: processedRadarData,
        filtered: processedFilteredData,
        sightings: processedSightingsData
      };
    } catch (error) {
      console.error('Error fetching hail data:', error);
      throw error;
    }
  }, [supabase]);

  return {
    address,
    setAddress,
    coordinates,
    events,
    totalCount,
    loading,
    error,
    dataSource,
    setDataSource,
    handleSearch,
    radius,
    setRadius,
    yearsBack,
    setYearsBack,
    getCoordinates, // Ensure getCoordinates is exported
    fetchHailDataForLocation,
  };
}

export default useStormEvents;